import React from "react";
import DrawingCanvas from "./components/DrawingCanvas";
import { Analytics } from "@vercel/analytics/react";

import "./App.css";

function App() {
    return (
        <div>
            <Analytics />
            <DrawingCanvas />
        </div>
    );
}

export default App;
