import React, { useState, useCallback, useEffect, useRef } from "react";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

const DrawingCanvas = () => {
    const canvasRef = useRef(null);
    const otherUsersCanvasesRef = useRef({});
    const drawing = useRef(false);
    const touchMoved = useRef(false);
    const startPoint = useRef({ x: 0, y: 0 });
    const userId = useRef(uuidv4());
    const [isConnected, setIsConnected] = useState(false);
    const [allowReconnect, setAllowReconnect] = useState(true);
    const [activeUsers, setActiveUsers] = useState([]);

    const socket = useRef(null);

    const onSelectColor = (color) => {
        setCurrentColor(color);
    };

    // Function to manually reconnect
    const handleReconnect = () => {
        if (socket.current && allowReconnect) {
            socket.current.connect();
        }
    };

    const colors = [
        "red",
        "blue",
        "green",
        "yellow",
        "orange",
        "brown",
        "pink",
        "cyan",
        "magenta",
        "limegreen",
        "teal",
        "olive",
        "coral",
        "gold",
        "violet",
        "aqua",
        "indigo",
        "chocolate",
        "crimson",
        "deepskyblue",
        "lightcoral",
        "orangered",
        "plum",
        "royalblue",
        "sandybrown",
        "tomato",
        "turquoise",
        "yellowgreen",
    ];

    const userColor = colors[Math.floor(Math.random() * colors.length)];

    const [currentColor, setCurrentColor] = useState(userColor);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const devicePixelRatio = window.devicePixelRatio || 1;
            const rect = canvas.getBoundingClientRect();

            canvas.width = rect.width * devicePixelRatio;
            canvas.height = rect.height * devicePixelRatio;

            const context = canvas.getContext("2d");
            context.scale(devicePixelRatio, devicePixelRatio);
        }
    }, []);

    const getCoordinates = (event) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const devicePixelRatio = window.devicePixelRatio || 1;
        const scaleX = canvasRef.current.width / devicePixelRatio / rect.width;
        const scaleY =
            canvasRef.current.height / devicePixelRatio / rect.height;
        let x, y;
        if (event.type.includes("touch")) {
            x = (event.touches[0].clientX - rect.left) * scaleX;
            y = (event.touches[0].clientY - rect.top) * scaleY;
        } else {
            x = (event.clientX - rect.left) * scaleX;
            y = (event.clientY - rect.top) * scaleY;
        }
        return { x, y };
    };

    const draw = useCallback(
        (event) => {
            if (
                !drawing.current ||
                (!touchMoved.current && event.type.includes("touch"))
            ) {
                return;
            }
            event.preventDefault();

            const { x, y } = getCoordinates(event);
            const context = canvasRef.current.getContext("2d");

            context.beginPath(); // Begin a new path for the drawing
            context.moveTo(startPoint.current.x, startPoint.current.y);
            context.lineTo(x, y);
            context.strokeStyle = currentColor;
            context.lineWidth = 2;
            context.lineCap = "round";
            context.stroke();
            context.closePath(); // Close the current path

            if (socket.current && socket.current.connected) {
                socket.current.emit("drawing", {
                    userId: userId.current,
                    startX: startPoint.current.x,
                    startY: startPoint.current.y,
                    x: x,
                    y: y,
                    type: "line",
                    color: currentColor,
                });
            }

            startPoint.current = { x, y };
        },
        [currentColor, socket.current], // Include socket.current in dependency array
    );

    const drawSocketDrawing = useCallback((data) => {
        if (data.userId !== userId.current && data.type === "line") {
            const context = canvasRef.current.getContext("2d");
            context.beginPath();
            context.moveTo(data.startX, data.startY);
            context.lineTo(data.x, data.y);
            context.strokeStyle = data.color;
            context.lineWidth = 2;
            context.lineCap = "round";
            context.stroke();
            context.closePath();
        }
    }, []);

    const fadeCanvas = useCallback(() => {
        const context = canvasRef.current.getContext("2d");
        context.save();
        context.globalAlpha = 0.35;
        context.fillStyle = "white";
        context.fillRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height,
        );
        context.restore();
    }, []);

    const startDrawing = useCallback(
        (event) => {
            drawing.current = true;
            touchMoved.current = false;
            const { x, y } = getCoordinates(event);
            startPoint.current = { x, y };
            if (!event.type.includes("touch")) {
                draw(event);
            }
        },
        [draw],
    );

    const handleTouchMove = useCallback(
        (event) => {
            touchMoved.current = true;
            draw(event);
        },
        [draw],
    );

    const stopDrawing = useCallback(() => {
        drawing.current = false;
        touchMoved.current = false;
        const context = canvasRef.current.getContext("2d");
        context.beginPath();
    }, []);

    useEffect(() => {
        socket.current = io("socket.doodlewalls.com:8443", {
            autoConnect: false,
        });
        socket.current.connect();
        const canvas = canvasRef.current;

        canvas.addEventListener("mousedown", startDrawing);
        canvas.addEventListener("mouseup", stopDrawing);
        canvas.addEventListener("mousemove", draw);
        canvas.addEventListener("touchstart", startDrawing, { passive: false });
        canvas.addEventListener("touchend", stopDrawing, { passive: false });
        canvas.addEventListener("touchmove", handleTouchMove, {
            passive: false,
        });

        socket.current.on("drawing", drawSocketDrawing);

        socket.current.on("activeUsers", (users) => {
            setActiveUsers(users);
        });

        const fadeInterval = setInterval(fadeCanvas, 30000); // adjust the interval as needed

        // Composite other users' drawings periodically
        const compositeDrawings = () => {
            const mainContext = canvasRef.current.getContext("2d");
            Object.values(otherUsersCanvasesRef.current).forEach(
                (userCanvas) => {
                    mainContext.drawImage(userCanvas, 0, 0);
                },
            );
            requestAnimationFrame(compositeDrawings);
        };
        requestAnimationFrame(compositeDrawings);

        // Event listeners for socket connection
        socket.current.on("connect", () => {
            setIsConnected(true);
        });

        socket.current.on("disconnect", () => {
            setIsConnected(false);
            setAllowReconnect(true);
        });

        return () => {
            canvas.removeEventListener("mousedown", startDrawing);
            canvas.removeEventListener("mouseup", stopDrawing);
            canvas.removeEventListener("mousemove", draw);
            canvas.removeEventListener("touchstart", startDrawing);
            canvas.removeEventListener("touchend", stopDrawing);
            canvas.removeEventListener("touchmove", handleTouchMove);
            clearInterval(fadeInterval);
            // Clean up the event listeners using `socket.current`
            if (socket.current) {
                socket.current.off("drawing", drawSocketDrawing);
                socket.current.off("connect");
                socket.current.off("disconnect");
                socket.current.off("activeUsers");
                socket.current.disconnect();
            }
        };
    }, [
        draw,
        startDrawing,
        stopDrawing,
        handleTouchMove,
        socket,
        fadeCanvas,
        drawSocketDrawing,
    ]);

    // Connection status indicator component
    const ConnectionStatusIndicator = () => {
        const isConnected = socket?.current?.connected;
        const tooltipText = isConnected
            ? "Connected to Server"
            : "Disconnected from Server";
        const [isHovered, setIsHovered] = useState(false);

        const buttonStyle = {
            marginRight: "10px",
            padding: "5px 10px",
            fontSize: "14px",
            backgroundColor: isHovered ? "#ff4500" : "#32cd32",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s ease, opacity 0.3s ease",
            opacity: isConnected ? 0 : 1, // Fully transparent when connected
            pointerEvents: isConnected ? "none" : "auto", // Disable pointer events when button is invisible
        };

        return (
            <div
                style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 20,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <button
                    onClick={handleReconnect}
                    style={buttonStyle}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    Reconnect
                </button>
                <div
                    style={{
                        height: "15px",
                        width: "15px",
                        borderRadius: "50%",
                        backgroundColor: isConnected ? "green" : "grey",
                    }}
                    title={tooltipText}
                ></div>
            </div>
        );
    };

    const ColorPicker = ({ onSelectColor, currentColor }) => (
        <div className="color-picker-container">
            <div className="color-picker">
                {colors.map((color) => (
                    <button
                        key={color}
                        className={`color-button ${
                            currentColor === color ? "selected-color" : ""
                        }`}
                        style={{ backgroundColor: color }}
                        onClick={() => onSelectColor(color)}
                        aria-label={`Select ${color}`}
                    />
                ))}
            </div>
        </div>
    );

    const UserCount = ({ userCount }) => {
        return (
            <div
                style={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                    display: "flex",
                    alignItems: "center",
                    zIndex: 20,
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1024 1024"
                    height="20"
                    width="20"
                    version="1.1"
                >
                    <path d="M170.666667 938.666667a341.333333 341.333333 0 1 1 682.666666 0H170.666667z m341.333333-384c-141.44 0-256-114.56-256-256s114.56-256 256-256 256 114.56 256 256-114.56 256-256 256z" />
                </svg>
                <span style={{ marginLeft: "5px" }}>{userCount}</span>
            </div>
        );
    };

    return (
        <div className="relative w-full h-screen flex justify-center items-center">
            <ConnectionStatusIndicator />
            <UserCount userCount={activeUsers.length} />
            <canvas
                ref={canvasRef}
                className="absolute top-0 left-0 w-full h-full"
                style={{ marginBottom: "50px" }}
            ></canvas>
            <div className="controls absolute bottom-0 flex justify-center w-full p-4  bg-opacity-75">
                <ColorPicker
                    onSelectColor={onSelectColor}
                    currentColor={currentColor}
                />
            </div>
            <style>
                {`
                @media (max-width: 768px) { 
                    .controls {
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        z-index: 10;
                    }

                    canvas {
                        height: calc(100vh - 50px);
                    }
                }
            `}
            </style>
        </div>
    );
};

export default DrawingCanvas;
